<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="searchform">
            <el-form-item label="imei" prop="imei">
              <el-input v-model="formInline.imei" placeholder="imei"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="seachFun">查询</el-button>
              <el-button type="primary" @click="resetFun">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-table
          :data="reader"
          border
          stripe
          style="width: 100%"
      >
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="imei" prop="imei"></el-table-column>
        <el-table-column label="经度" prop="longitude"></el-table-column>
        <el-table-column label="纬度" prop="latitude"></el-table-column>
        <el-table-column label="星值数" prop="number"></el-table-column>
        <el-table-column label="CN" prop="CN">
          <template #default="scope">
            <span v-html="scope.row.CN"></span>
          </template>
        </el-table-column>
        <el-table-column label="上报时间" prop="create_at" width="200"></el-table-column>
      </el-table>
      <el-pagination
          :page-size="formInline.size"
          :page-sizes="[10, 20, 50]"
          :total="listCount"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          fixed="right"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
import user from "../model/user";

export default {
  name: "gps_test",
  data() {
    return {
      dialogFormVisible: false,
      formInline: {
        imei: '',
        page: 1,
        size: 10
      },
      reader: [],
      listCount: 0
    }
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      const ret = await user.gpsTest(this.formInline)
      if (ret.code === 200) {
        this.reader = ret.data;
        this.listCount = ret.count;
      }
    },
    seachFun() {
      this.loadData();
    },
    resetFun() {
      this.formInline.imei = ''
      this.loadData();
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.loadData();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.card-header {
  display: flex;
  align-items: center;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}
</style>
