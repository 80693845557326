import request from '../utils/request'

export default {
  async login (data) {     //登录
    return await request('/v1/user/login', data, 'POST')
  },
  async reset (data) {     // 用户密码重置
    return await request('/v1/user/reset', data, 'POST')
  },
  async app (data) {      // app用户列表
    return await request('/v1/user/app', data, 'POST')
  },
  async list (data) {     // 手环用户列表
    return await request('/v1/user/list', data, 'POST')
  },
  async operate (data) {    // 编辑/新增用户
    return await request('/v1/user/operate', data, 'POST')
  },
  async operateInfo (data) {    // 编辑用户详情信息
    return await request('/v1/user/operate_info', data, 'POST')
  },
  async profile (data) {      // 获取用户详情
    return await request('/v1/user/profile', data, 'POST')
  },
  async untie (data) {      // 解绑用户
    return await request('/v1/user/untie', data, 'POST')
  },
  async inspect (data) {      // 额温枪
    return await request('/v1/user/inspect', data, 'POST')
  },
  async reader (data) {      // 读卡器
    return await request('/v1/user/reader', data, 'POST')
  },
  async gpsTest (data) {      // 读卡器
    return await request('/v1/data/gps_test', data, 'POST')
  },
  async getUserInfo (data) {      // 获取用户信息
    return await request('/v1/user/userInfo', data, 'POST')
  },
  async editUserInfo (data) {      // 更新用户信息
    return await request('/v1/user/editUserInfo', data, 'POST')
  },
  async deleteUserInfo (data) {      // 更新用户信息
    return request('/v1/user/deleteUserInfo', data, 'POST');
  }
}
